//GuessingArea.js
import { useState } from "react";
import { Button, TextField, Box } from "@mui/material";
import { Alert } from "@mui/lab";
import styles from "./GuessingArea.module.css";
import React from "react";

const GuessingArea = (props) => {
  const { onInputChange, onSubmitGuessClick, songs, query } = props;

  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [feedbackSeverity, setFeedbackSeverity] = useState("error");
  const [feedbackOpacity, setFeedbackOpacity] = useState(1);

  const [timeoutId, setTimeoutId] = useState(null);
  

  let localTimeoutId; // Declare with `let` before using it in the callback

  const handleSubmit = () => {
    onSubmitGuessClick((isCorrect) => {
      // Clear any existing timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (isCorrect) {
        setFeedbackMessage("Correct! You guessed the song!");
        setFeedbackSeverity("success");
      } else {
        setFeedbackMessage("Incorrect guess. Try again!");
        setFeedbackSeverity("error");
        //handle skip:
        
      }

      // Set a timeout to clear the feedback message after 5 seconds
      localTimeoutId = setTimeout(() => {
        setFeedbackOpacity(0);
        setTimeout(() => {
        setFeedbackMessage(null);
        setFeedbackOpacity(1);
        }, 1000);
      }, 5000);
      // Store the timeout ID in the component's state so that we can clear it later
      setTimeoutId(localTimeoutId);
    });
  };

  const [localQuery, setLocalQuery] = useState("");

  const isInputValid = songs.some(
    (song) =>
      `${song.title} - ${song.artist}`.toLowerCase() === query.toLowerCase()
  );

  return (
    <Box className={styles.guessingArea}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <TextField
        id="song-guess-input"
        label="Know it? Search for the artist / title"
        variant="outlined"
        size="small"
        className={styles.guessInput} // Remove the quotes
        onChange={(e) => {
          const inputValue = e.target.value;
          onInputChange(inputValue);
          setLocalQuery(inputValue); // Updates the local state
        }}
        sx={{ mt: 2 }}
      />
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={!isInputValid}
        className={`${styles.submitButton} ${
          !isInputValid ? styles.submitButtonDisabled : ""
        }`} // Remove the quotes
        sx={{ mt: 2 }}
      >
        Submit Guess
      </Button>

      {feedbackMessage && (
        <Alert
          severity={feedbackSeverity}
          className={styles.feedback}
          sx={{ mt: 2 }}
          style={{ opacity: feedbackOpacity, transition: "opacity 1s" }}
        >
          {feedbackMessage}
        </Alert>
      )}
    </Box>
  );
};

export default GuessingArea;
