//Suggestions.js
import React from 'react';
import styles from './Suggestions.module.css';

const Suggestions = ({ suggestions, onSuggestionClick }) => {
  return (
    <div className={styles.suggestions} id="suggestions">
      {suggestions.map((song) => (
        <div
          key={`${song.title} - ${song.artist}`}
          className={styles.suggestion}
          onClick={() => onSuggestionClick(`${song.title} - ${song.artist}`)}
        >
          {song.title} - {song.artist}
        </div>
      ))}
    </div>
  );
};

export default Suggestions;
