// Player.js
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import styles from "./Player.module.css";
import YouTube from "react-youtube";

const Player = ({
  videoId,
  audioRef,
  onPlayButtonClick,
  onSkipButtonClick,
  attempt,
  onReady,
  onStateChange,
  remainingDuration,
  playTimes,
  currentAttempt,
  isVideoPlaying,
  desiredPlayTime,
}) => {
  const [player, setPlayer] = useState(null);
  // Set the options for the YouTube player
  const opts = {
    height: "0", // Set the height to 0 to hide the player
    width: "0", // Set the width to 0 to hide the player
    playerVars: {
      autoplay: 0, // Don't autoplay the video
      //controls: 0, // Hide the video controls
      modestbranding: 1, // Hide the YouTube logo
      rel: 0, // Don't show related videos
      showinfo: 0, // Don't show the video title
    },
  };

  let skipString = "Skip +" + Math.round((playTimes[currentAttempt+1 ] - playTimes[currentAttempt])) + "s";
  if (currentAttempt === 5) { skipString = "Give up"  }

  return (
    <div className={styles.player}>
      <button
        className={styles.playButton}
        onClick={() => onPlayButtonClick()}
      >
        ▶
      </button>
      <Button
        className={styles.skipButton}
        variant="contained"
        color="secondary"
        onClick={onSkipButtonClick}
      >
        {skipString}
      </Button>
      <YouTube
      videoId={videoId}
      opts={opts}
      onReady={event => {
        onReady(event);
        setPlayer(event.target); // save the player object when the video is ready
      }}
          onStateChange={(event) => onStateChange(event, attempt)}
      />
      <audio ref={audioRef} />
    </div>
  );
};

export default Player;
