import React from "react";
import "./Message.css";

const Message = ({ isGameOver, createShareMessage, message }) => {
  const shareMessage = createShareMessage();

  return (
    <>
      {isGameOver ? (
        <div>
          <div className="message game-over">
            Game Over! Refresh the page to start a new game.
          </div>
          <div className="share-message">{shareMessage}</div>
          <button
            className="share-button"
            onClick={() => copyToClipboard(shareMessage)}
          >
            Share
          </button>
        </div>
      ) : (
        <div className="message">{message}</div>
      )}
    </>
  );
};


const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Copied to clipboard:", text);
    },
    (err) => {
      console.error("Could not copy text:", err);
    }
  );
};

export default Message;
