//GuessesList.js
import React from "react";
import "./GuessesList.css";
//import songs from "./SongsList";

const GuessesList = ({ guesses, resultList }) => {

  const defaultGuesses = Array(6).fill(null);

  return (
    <div className="guesses-list">
      <ul>
        {defaultGuesses.map((_, index) => {
          const guess = guesses[index];
          const result = resultList[index];

          return (
            <li key={index} className={result === 1 ? "correct" : result === 2 ? "correct-artist" : "incorrect"}>
              {guess || ' '} {/* Render the guess if it exists, or a blank space if it doesn't */}
              {result === 0 && (
                <span style={{ color: "red", marginLeft: "10px" }}>✕</span>
              )}
              {result === 2 && (
                <span style={{ color: "orange", marginLeft: "10px" }}>✕</span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default GuessesList;
