import React, { useEffect, useState } from 'react';
import './ProgressBar.css';

const ProgressBar = ({
  key,
  isVideoPlaying,
  currentAttempt,
  playTimes,
  remainingDuration,
}) => {
  const [progress, setProgress] = useState(0);
  const [transitionDuration, setTransitionDuration] = useState(0);
  const [availableProgress, setAvailableProgress] = useState(0);
  const [playTimesPercentagesCorrected, setPlayTimesPercentagesCorrected] = useState({});

  useEffect(() => {
    let totalPlayTime = Object.values(playTimes).pop(); // Get the last value as total time

    // Calculate the correct percentages
    let calculatedPercentages = {};
    for (let key in playTimes) {
      calculatedPercentages[key] = (playTimes[key] / totalPlayTime) * 100;
    }
    setPlayTimesPercentagesCorrected(calculatedPercentages);
  }, [playTimes]); // Recalculate only when playTimes changes

  useEffect(() => {
    if (isVideoPlaying) {
      setProgress(playTimesPercentagesCorrected[currentAttempt]);
      setTransitionDuration(remainingDuration);
    }
    setAvailableProgress(playTimesPercentagesCorrected[currentAttempt] || 100);
  }, [isVideoPlaying, currentAttempt, playTimesPercentagesCorrected, remainingDuration]);

  const playTimesPercentagesArray = Object.values(playTimesPercentagesCorrected);

  return (
    <div className="progress-bar">
      <div
        className="progress-fill available"
        style={{
          width: `${availableProgress}%`,
        }}
      ></div>
      <div
        className="progress-fill actual"
        style={{
          width: `${progress}%`,
          transition: `width ${transitionDuration}s linear`,
        }}
      ></div>
      {playTimesPercentagesArray.slice(0, -1).map((percentage, index) => (
        <div
          key={index}
          className="marker"
          style={{
            left: `${percentage}%`,
          }}
        ></div>
      ))}
    </div>
  );
};

export default ProgressBar;
