//App.js
import logo from "./logo.svg";
import "./App.css";
import "./global.css";
import Player from "./components/Player";
import GuessingArea from "./components/GuessingArea";
import Suggestions from "./components/Suggestions";
import Header from "./components/Header";
import songs from "./components/SongsList";
import ProgressBar from './components/ProgressBar';
import "./components/Message.css"; 
import Message from "./components/Message";
import GuessesList from "./components/GuessesList";
import ElapsedTime from "./components/ElapsedTime";
import React, { useState, useRef, useEffect } from "react";
import SongDisplay from "./components/SongDisplay";

function App() {
  
  //Lots of variables
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [message, setMessage] = useState(""); 
  const audio = useRef(null); //dont know what this does
  const [currentTime, setCurrentTime] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false); //false because the video is not playing when the app starts
  const [player, setPlayer] = useState(true); //true because player is ready when the app starts
  const [currentAttempt, setCurrentAttempt] = useState(0); //0 because the player has not made any attempts yet.
  const [isSuccess, setIsSuccess] = useState(false); //whether the player succeeded or not
  const [isGameOver, setIsGameOver] = useState(false); //whether the game is over or not
  const resultList = useRef([]); //list of results. 0 = wrong guess, 1 = correct guess, 2 = correct artist but wrong song
  const [hiddenAttempt, setHiddenAttempt] = useState(0); //tracks the number of hidden attempts (every time the player clicks play)
  const [guesses, setGuesses] = useState([]);
  const testMode = false; // currently not working

  const [countdown, setCountdown] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [startTime, setStartTime] = useState(null);


  const playVideo = () => {
    setStartTime(Date.now());
    player.playVideo();
};

  const stopVideo = () => {
    console.log('countdown:', countdown);
    player.pauseVideo();
    console.log('stopVideo called');
    setCountdown(0); // reset countdown
    player.seekTo(0); // Reset the video to the beginning
  };

  // Whenever `countdown` changes, we update the timer
  useEffect(() => {
    clearTimeout(timeoutId); // clear the existing timer

    if (countdown > 0) {
      const id = setTimeout(stopVideo, countdown * 1000);
      console.log('id:', id);
      console.log('countdown:', countdown);
      setTimeoutId(id); // store the timer ID so we can clear it later
    }
  }, [countdown]);

  //handles the skip button
  const handleSkip =  () => {
    console.log(resultList.current);
    setStartTime(Date.now()); //Update the start time when the player clicks the skip button
    // Calculate the elapsed time
    const elapsed = (Date.now() - startTime) / 1000;
    // Calculate the remaining duration for the next segment
    const remaining = playTimes[currentAttempt + 1] - elapsed;
    setCountdown(() => remaining);

    if (currentAttempt < 6) {
      resultList.current.push(0); //pushes the result to the result list
      setGuesses([...guesses, "Skipped"]); // Add "Skipped" to the guesses state   
    } 
    
    setCurrentAttempt((currentAttempt) => {
      // if the player has made 6 attempts, the game is over
      if (currentAttempt >= 5) { 
        setCurrentAttempt(6); 
        setIsGameOver(true);
      }
      return currentAttempt + 1;
    });
    
  };

  const playTimes = {
    0: 2.4,
    1: 4.8,
    2: 7.5,
    3: 10.5,
    4: 13.5,
    5: 16.5
  };
  const playTimesPercentages = {
    0: 14.54,
    1: 29.09,
    2: 45.45,
    3: 63.63,
    4: 81.81,
    5: 100
  };
  //gets a new random song (should maybe be moved to a separate file)
  const [randomSong, setRandomSong] = useState(null);
  
  function dailySongIndex() {
    const startDate = new Date('2023-06-01'); // Arbitrary start date
    const currentDate = new Date();
  
    // Calculate the difference in days
    const diffTime = Math.abs(currentDate - startDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
    // Return the song index
    return diffDays % songs.length;
  }

  useEffect(() => {
    if (!testMode && !randomSong) {
      const index = dailySongIndex();
      setRandomSong(songs[index]);
    }
  }, [testMode, randomSong]);
  
  //handles the input and shows suggestions
  const handleInputChange = (inputValue) => { 
    setQuery(inputValue); //sets query to the input value

    //if input is empty, don't show suggestions
    if (!inputValue.trim()) { 
      setSuggestions([]); 
      return;
    }
    
    //if the input is not empty, show suggestions
    const filteredSongs = songs //filters out songs that don't match the input
      .filter((song) => {
        const songTitleArtist = `${song.title} - ${song.artist}`.toLowerCase();
        return songTitleArtist.includes(inputValue.toLowerCase());
      })
      .slice(0, 5); //only show 5 suggestions

    setSuggestions(filteredSongs); //sets suggestions to the filtered songs
  };

  //håndterer klikk på en suggestion
  const handleSuggestionClick = (value) => {
    setQuery(value);
    setSuggestions([]);
    // Set the input value directly
    const inputElement = document.getElementById("song-guess-input");
    if (inputElement) {
      inputElement.value = value;
    }
  };

  //handles the submit button
  const handleSubmitGuessClick = (callback) => {
    // Check if the input is empty or doesn't match any songs in the catalog
    if (!query || !songs.some(song => `${song.title} - ${song.artist}`.toLowerCase() === query.toLowerCase())) {
      return;
    }
    
    // Check if the input matches the current song
    let newCurrentAttempt = currentAttempt;
    if (query.trim().toLowerCase() === `${randomSong.title} - ${randomSong.artist}`.toLowerCase()) {
      setIsSuccess(true);
      resultList.current.push(1); //pushes the result to the result list
      newCurrentAttempt = 6;
      setIsGameOver(true); // Set isGameOver to true when the correct song is guessed
      callback(true); 
    } else if (query.trim().toLowerCase() === `${randomSong.artist}`.toLowerCase()) {
      resultList.current.push(2); //pushes the result to the result list
      callback(false);
    } else {
      resultList.current.push(0); //pushes the result to the result list
      callback(false);
    }
  
    setGuesses([...guesses, query]);
  
    newCurrentAttempt++; // Increment currentAttempt by 1
  
    // Check if the attempts are exhausted
    if (newCurrentAttempt > 5) {
      newCurrentAttempt = 6; // Set currentAttempt to 6 when the attempts are exhausted
      setIsGameOver(true); // Set isGameOver to true when the attempts are exhausted
    }
  
    setCurrentAttempt(newCurrentAttempt);
    console.log("submit2, currentAttempt: " + newCurrentAttempt);
  
    // Clear the input value after player submits the guess
    setQuery("");
    const inputElement = document.getElementById("song-guess-input");
    if (inputElement) {
      inputElement.value = "";
      // Focus the input element after clearing the value
      inputElement.focus();
    }
  };

  //readies the player
  const handlePlayerReady = (event) => {
    console.log("YouTube player is ready:", event.target);
    setPlayer(event.target);
    
    // Pre-buffer the video
    event.target.playVideo();
    event.target.pauseVideo();
  };

  const handlePlayButtonClick = () => {
    setHiddenAttempt(hiddenAttempt + 1);
    console.log("Play button clicked, handlePlayButtonClick called123123123");
    if (!player) { //if player is not ready
      console.log("Player is not ready yet");
      return;
    }

    // If the player is already playing, pause it
    if (player.getPlayerState() === 1) { // 1 = playing, 2 = paused, 0 = ended
      player.pauseVideo();
      //setCurrentTime(0); // Reset the current time to 0 when the play button is clicked while the video is playing
      console.log("Player paused");
      return;
    }

    // If the player is not playing or paused, play it
    else {
      console.log("videoId:", randomSong.videoId);
      setCurrentTime(0); //Reset the current time to 0 when the play button is clicked
      setCountdown(playTimes[currentAttempt]);
      playVideo();
    }
  };

  //handles changes in player status, should maybe be moved to Player.js
  const handlePlayerStateChange = (event) => {
    console.log("Player state changed:", event.data);
  
    if (event.data === 1) { // 1 = playing, 2 = paused, 0 = ended, 3 = 
      console.log(`Video is playing. Attempt: ${currentAttempt}`);
        setIsVideoPlaying(true);
    } 
    
    else if (event.data === 2 || event.data === 0) { // 2 = paused, 0 = ended
      setIsVideoPlaying(false); // Set isVideoPlaying to false when the video is paused (2) or ended (0)
    }
  };

  //Creates the message that appears when the game is over
  //Should be suited for sharing on social media etc
  const createShareMessage = () => {
    const tempMessage = [];
    const messageParts = [
      "#Heardit",
      "\n"
    ];

    //creates 6 boxes with the results of the guesses
    while (tempMessage.length < 6) { // While the length of the tempMessage array is less than 6
      if (resultList.current[tempMessage.length] === 0) {
        tempMessage.push("🟥"); // Red box for incorrect guesses
      } else if (resultList.current[tempMessage.length] === 2) {
        tempMessage.push("🟨"); // Yellow box for correct artist but wrong song
      } else {
        tempMessage.push("🟩"); // Green box for correct guesses
      }
    }
    messageParts.push(tempMessage.join(""));

    if (isSuccess === true) {
    messageParts.push("🎉\nhttps://heardit.eu"); //game link
    } else {
    messageParts.push("🥲\nhttps://heardit.eu"); //game link
    }
    return messageParts.join("");
  };

  //renders the app
  return (
    <div className="App">

      <Header />
      <GuessesList guesses={guesses} resultList={resultList.current} />
      <ProgressBar 
      key={hiddenAttempt} // Add a key prop that depends on the current hidden attempt to force the component to re-render
      isVideoPlaying={isVideoPlaying} //progress bar should only move when the video is playing
      currentAttempt={currentAttempt} //current attempt should be passed to the progress bar
      playTimes={playTimes} //play times should be passed to the progress bar
      playTimesPercentages={playTimesPercentages} //play times percentages should be passed to the progress bar
      remainingDuration={countdown} //remaining duration should be passed to the progress bar
      />
      <ElapsedTime currentTime={currentTime} />
      {isGameOver && <SongDisplay song={randomSong} />}
          {randomSong ? (
            <Player
            videoId={randomSong.videoId}
            audioRef={audio}
            onPlayButtonClick={() => {
              //handleFirstInteraction();
              handlePlayButtonClick();
            }}
            onSkipButtonClick={() => {
              //handleFirstInteraction();
              handleSkip();
            }}
            attempt={currentAttempt}
            onReady={handlePlayerReady}
            onStateChange={handlePlayerStateChange}
            isVideoPlaying={isVideoPlaying}
            remainingDuration={playTimes[currentAttempt] - currentTime}
            playTimes={playTimes}
            currentAttempt={currentAttempt}
            />
            ) : (
              <div className="loading">Loading...</div>
              )}
        <Suggestions
          suggestions={suggestions}
          onSuggestionClick={handleSuggestionClick}
        />
      <GuessingArea
        onInputChange={handleInputChange}
        onSubmitGuessClick={handleSubmitGuessClick}
        songs={songs} // pass the songs prop directly to the GuessingArea component
        query={query} // pass the query state to the GuessingArea component
      />
      <div className="message-container">
      <div className="message">{message}</div>
      </div>
      <Message isGameOver={isGameOver} createShareMessage={createShareMessage} message={message} />
  </div>
);
}

export default App;