//Header.js
import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import styles from './Header.module.css';  // Import your CSS module

const Header = () => {
  return (
    <AppBar position="static" className={styles.header}>
      <Toolbar>
        <Typography variant="h4" className={styles.title}>
          Heardit
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
