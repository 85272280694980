// SongDisplay.js
import React from 'react';

const SongDisplay = ({ song }) => {
  // Extract song Spotify URL from song object
  const spotifyURL = song.spotifyUrl; 

  return (
    <div>
      {spotifyURL && (
        <iframe 
          src={`https://open.spotify.com/embed/track/${spotifyURL}`}
          width="100%"
          height="180"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        />
      )}
    </div>
  );
};

export default SongDisplay;