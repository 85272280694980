//SongsList.js
const songs = [
    { title: "Bohemian Rhapsody", artist: "Queen", videoId: "fJ9rUzIMcZQ", year: 1975 , spotifyUrl: "4u7EnebtmKWzUH433cf5Qv?si=88fc4ab424e949c2" },
    { title: "Like a Rolling Stone", artist: "Bob Dylan", videoId: "IwOfCgkyEj0", year: 1965 , spotifyUrl: "3AhXZa8sUQht0UEdBJgpGc?si=f435fadade28451d" },
    { title: "Billie Jean", artist: "Michael Jackson", videoId: "Zi_XLOBDo_Y", year: 1983 , spotifyUrl: "5ChkMS8OtdzJeqyybCc9R5?si=b08f5dfdaeac4cbc" },
    { title: "Superstition", artist: "Stevie Wonder", videoId: "0CFuCYNx-1g", year: 1972 , spotifyUrl: "4pbG9SUmWIvsROVLF0zF9s?si=3d867f2ee34f4774" },
    { title: "I Want to Hold Your Hand", artist: "The Beatles", videoId: "v1HDt1tknTc", year: 1963 , spotifyUrl: "4pbG9SUmWIvsROVLF0zF9s?si=3d867f2ee34f4774" },
    { title: "What's Going On", artist: "Marvin Gaye", videoId: "H-kA3UtBj4M", year: 1971 , spotifyUrl: "3Um9toULmYFGCpvaIPFw7l?si=ed7907cf29fe445d" },
    { title: "Like a Prayer", artist: "Madonna", videoId: "79fzeNUqQbQ", year: 1989 , spotifyUrl: "1z3ugFmUKoCzGsI6jdY4Ci?si=e4604c4e5a6b4b43" },
    { title: "Smells Like Teen Spirit", artist: "Nirvana", videoId: "hTWKbfoikeg", year: 1991 , spotifyUrl: "4CeeEOM32jQcH3eN9Q2dGj?si=21b30252d1244af6" },
    { title: "Rolling in the Deep", artist: "Adele", videoId: "rYEDA3JcQqw", year: 2011 , spotifyUrl: "4OSBTYWVwsQhGLF9NHvIbR?si=f2300feebbf84c86" },
    { title: "Uptown Funk", artist: "Mark Ronson ft. Bruno Mars", videoId: "OPf0YbXqDm0", year: 2014 , spotifyUrl: "32OlwWuMpZ6b0aN2RZOeMS?si=a5699cec191a43cb" },
    { title: "Shape of You", artist: "Ed Sheeran", videoId: "Vds8ddYXYZY", year: 2017 , spotifyUrl: "7qiZfU4dY1lWllzX7mPBI3?si=18064fc8b5cb4883" },
    { title: "I Will Always Love You", artist: "Whitney Houston", videoId: "3JWTaaS7LdU", year: 1992 , spotifyUrl: "4eHbdreAnSOrDDsFfc4Fpm?si=3b4401fcf868445e" },
    { title: "Lose Yourself", artist: "Eminem", videoId: "_Yhyp-_hX2s", year: 2002 , spotifyUrl: "7MJQ9Nfxzh8LPZ9e9u68Fq?si=668752d6f4fa49bc" },
    { title: "Dancing Queen", artist: "ABBA", videoId: "xFrGuyw1V8s", year: 1976 , spotifyUrl: "0GjEhVFGZW8afUYGChu3Rr?si=81757babf8c84c92" },
    { title: "Close To You", artist: "The Carpenters", videoId: "iFx-5PGLgb4", year: 1970 , spotifyUrl: "2kyVQg00pphEufGT59M2XH?si=96df901dcf514a01" },
    { title: "I Will Survive", artist: "Gloria Gaynor", videoId: "nXMPx4DDVWM", year: 1978 , spotifyUrl: "7rIovIsXE6kMn629b7kDig?si=13ee78396e24473f" },
    { title: "Homecoming", artist: "Kanye West", videoId: "LQ488QrqGE4", year: 2007 , spotifyUrl: "4iz9lGMjU1lXS51oPmUmTe?si=8e19487c35f04fe7" },
    { title: "I Wanna Dance With Somebody", artist: "Whitney Houston", videoId: "4M7LI5s6Xqk", year: 1987 , spotifyUrl: "2tUBqZG2AbRi7Q0BIrVrEj?si=02518049ced64213" },
    { title: "Take on Me", artist: "a-ha", videoId: "djV11Xbc914", year: 1985 , spotifyUrl: "2WfaOiMkCvy7F5fcp2zZ8L?si=d23dc03332bf49c0" },
    { title: "I'm Yours", artist: "Jason Mraz", videoId: "Rv78QjVByYc", year: 2008 , spotifyUrl: "1EzrEOXmMH3G43AXT1y7pA?si=f8a99dadbe074a97" },
    { title: "It's My Life", artist: "Bon Jovi", videoId: "lDK9QqIzhwk", year: 2000 , spotifyUrl: "0v1XpBHnsbkCn7iJ9Ucr1l?si=f793fab224de490e" },
    { title: "It's Not Unusual", artist: "Tom Jones", videoId: "k-HdGnzYdFQ", year: 1965 , spotifyUrl: "3TlIt0ReIxPsVZcOEivT5U?si=7e79ae788944444b" },
    { title: "Pink + White", artist: "Frank Ocean", videoId: "J6DKPfQul54", year: 2016 , spotifyUrl: "3xKsf9qdS1CyvXSMEid6g8?si=1abc67c9bf0e4cd3" },
    { title: "I'm Still Standing", artist: "Elton John", videoId: "ZHwVBirqD2s", year: 1983 , spotifyUrl: "1jDJFeK9x3OZboIAHsY9k2?si=4361edb3d9df47c8" },
    { title: "Hotel California", artist: "Eagles", videoId: "9cNUWtnI0BQ", year: 1976 , spotifyUrl: "40riOy7x9W7GXjyGp4pjAv?si=e51aba8d78214037" },
    { title: "Lost", artist: "Frank Ocean", videoId: "xjoBP7SDgaY", year: 2012 , spotifyUrl: "3GZD6HmiNUhxXYf8Gch723?si=bfa6f599714c4c36" },
    { title: "my boy", artist: "Billie Eilish", videoId: "dVUmSgzgOqs", year: 2017 , spotifyUrl: "1RGasjWLZ4qMN7wbtkLa3u?si=3071b57a05134d43" },
    { title: "Get Lucky", artist: "Daft Punk", videoId: "5NV6Rdv1a3I", year: 2013 , spotifyUrl: "2Foc5Q5nqNiosCNqttzHof?si=58b1aed14a0245dd" },
    { title: "Blurred Lines", artist: "Robin Thicke", videoId: "yyDUC1LUXSU", year: 2013 , spotifyUrl: "0n4bITAu0Y0nigrz3MFJMb?si=a021ed59e40c4e41" },
    { title: "Straight Outta Compton", artist: "N.W.A", videoId: "TMZi25Pq3T8", year: 1988 , spotifyUrl: "5QKM7U6l7bMdJItolkM4x4?si=32e67308e09d4892" },
    { title: "Everybody Wants to Rule the World", artist: "Tears for Fears", videoId: "Ij65wvAGX-c", year: 1985 , spotifyUrl: "4RvWPyQ5RL0ao9LPZeSouE?si=a9db65848b644def" },
    { title: "I Want You Back", artist: "Jackson 5", videoId: "s3Q80mk7bxE", year: 1969 , spotifyUrl: "5LxvwujISqiB8vpRYv887S?si=89c2595f37224351" },
    { title: "Black Space", artist: "Taylor Swift", videoId: "e-ORhEE9VVg", year: 2014 , spotifyUrl: "1u8c2t2Cy7UBoG4ArRcF5g?si=a35c8a5c96ef4419" },
    { title: "Formation", artist: "Beyoncé", videoId: "OI2jn3lJTAE", year: 2016 , spotifyUrl: "6g0Orsxv6glTJCt4cHsRsQ?si=1ffb69a09aa74483" },
    { title: "Everybody Hurts", artist: "R.E.M.", videoId: "5rOiW_xY-kc", year: 1993 , spotifyUrl: "6PypGyiu0Y2lCDBN1XZEnP?si=66f06f56a8dc427f" },
    { title: "All you need is love", artist: "The Beatles", videoId: "4EGczv7iiEk", year: 1967 , spotifyUrl: "5zqJlEJcn0EfnvAScH8swK?si=105d436a8ec949cb" },
    { title: "Free Fallin'", artist: "Tom Petty", videoId: "1lWJXDG2i0A", year: 1989 , spotifyUrl: "5tVA6TkbaAH9QMITTQRrNv?si=81dce90c01014488" },
    { title: "Black Beatles", artist: "Rae Sremmurd", videoId: "b8m9zhNAgKs", year: 2016 , spotifyUrl: "6fujklziTHa8uoM5OQSfIo?si=11761345d0004fdf" },
    { title: "In Da Club", artist: "50 Cent", videoId: "5qm8PH4xAss", year: 2003 , spotifyUrl: "7iL6o9tox1zgHpKUfh9vuC?si=377af0e0ea0b4183" },
    { title: "Mask Off", artist: "Future", videoId: "AMCwYdTJ_PE", year: 2017 , spotifyUrl: "0VgkVdmE4gld66l8iyGjgx?si=bad80ba19ed24f97" },
    { title: "Somebody Else", artist: "The 1975", videoId: "VNsd8Yo5zvc", year: 2016 , spotifyUrl: "4m0q0xQ2BNl9SCAGKyfiGZ?si=4ddf3cbaf5334c21" },
    { title: "Love Me", artist: "The 1975", videoId: "TJ5bZuUlftI", year: 2015 , spotifyUrl: "2HklyUw3TR56nVyVJAIhw5?si=dfbc4d634bb742f6" },
    { title: "Flowers", artist: "Miley Cyrus", videoId: "G7KNmW9a75Y", year: 2020 , spotifyUrl: "0yLdNVWF3Srea0uzk55zFn?si=0f98b4a2a3fa484c" },
    { title: "Ms Jackson", artist: "Outkast", videoId: "CSD2J8yaMmM", year: 2000 , spotifyUrl: "0I3q5fE6wg7LIfHGngUTnV?si=5750e9d87b1141b1" },
    { title: "Hey Ya!", artist: "Outkast", videoId: "Pv3pXjrtkyI", year: 2003 , spotifyUrl: "2PpruBYCo4H7WOBJ7Q2EwM?si=0aef4fb2ae894e70" },
    { title: "Redbone", artist: "Donald Glover", videoId: "4a4zu5t83yY", year: 2016 , spotifyUrl: "0WtDGnWL2KrMCk0mI1Gpwz?si=32c982955e6844ef" },
    { title: "3005", artist: "Donald Glover", videoId: "tG35R8F2j8k", year: 2013 , spotifyUrl: "5cc9Zbfp9u10sfJeKZ3h16?si=6d319d8386a54ac0" },
    { title: "Feels Like Summer", artist: "Donald Glover", videoId: "F1B9Fk_SgI0", year: 2018 , spotifyUrl: "7p4vHnYXkxlzvfePJVpcTr?si=629f5d53d7f14eb0" },
    { title: "Teenage Dirtbag", artist: "Wheatus", videoId: "FC3y9llDXuM", year: 2000 , spotifyUrl: "3LI4MmibTkXH5cGpCGZgyw?si=4efbd165aaec4c83" },
    { title: "The Real Slim Shady", artist: "Eminem", videoId: "eJO5HU_7_1w", year: 2000 , spotifyUrl: "3yfqSUWxFvZELEM4PmlwIR?si=99be4e14b98a47db" },
    { title: "Clint Eastwood", artist: "Gorillaz", videoId: "I7yqFVEvdY0", year: 2001 , spotifyUrl: "1RKUoGiLEbcXN4GY4spQDx?si=6e8f41a293064b74" },
    { title: "Feel Good Inc.", artist: "Gorillaz", videoId: "NxxjLD2pmlk", year: 2005 , spotifyUrl: "0d28khcov6AiegSCpG5TuT?si=3500a2533e6e4a7d" },
    { title: "Wake Me up When September Ends", artist: "Green Day", videoId: "ulRXvH8VOl8", year: 2004 , spotifyUrl: "3ZffCQKLFLUvYM59XKLbVm?si=e410df07dec84b9b" },
    { title: "The Bad Touch", artist: "Bloodhound Gang", videoId: "xat1GVnl8-k", year: 1999 , spotifyUrl: "5EYdTPdJD74r9EVZBztqGG?si=5eeb2d72adeb4915" },
    { title: "Roses", artist: "Outkast", videoId: "LNrA2YVDmPk", year: 2004 , spotifyUrl: "6bUNEbXT7HovLW6BgPCBsb?si=43a33dd67dd84880" },
    { title: "September", artist: "Earth Wind & Fire", videoId: "3cKtSlsYVEU", year: 1978 , spotifyUrl: "2grjqo0Frpf2okIBiifQKs?si=661028b7ff2942e4" },
    { title: "Say My Name", artist: "Destiny's Child", videoId: "Ajez9PaCgek", year: 1999 , spotifyUrl: "7H6ev70Weq6DdpZyyTmUXk?si=4a60ebb3fd314b94" },
    { title: "Without Me", artist: "Eminem", videoId: "tqxRidAWER8", year: 2002 , spotifyUrl: "7lQ8MOhq6IN2w8EYcFNSUk?si=9eaf0a7f82624ed6" },
    { title: "Flashing Lights", artist: "Kanye West", videoId: "ZAz3rnLGthg", year: 2007 , spotifyUrl: "5TRPicyLGbAF2LGBFbHGvO?si=c01c5306f1524d64" },
    { title: "Careless Whisper", artist: "George Michael", videoId: "g9VWhh8r5Vs", year: 1984 , spotifyUrl: "4jDmJ51x1o9NZB5Nxxc7gY?si=c98d6b11c9b24b37" },
    { title: "Beautiful", artist: "Christina Aguilera", videoId: "eAfyFTzZDMM", year: 2002 , spotifyUrl: "3TCauNPqFiniaYHBvEVoHG?si=cf5539b883de4ffa" },
    { title: "Do I Wanna Know?", artist: "Arctic Monkeys", videoId: "bpOSxM0rNPM", year: 2013 , spotifyUrl: "5FVd6KXrgO9B3JPmC8OPst?si=cf696b64c44c4b00" },
    { title: "Porcelain", artist: "Moby", videoId: "13EifDb4GYs", year: 1999 , spotifyUrl: "1hEh8Hc9lBAFWUghHBsCel?si=38f9563cca1c4856" },
    { title: "Eple", artist: "Röyksopp", videoId: "KkD7a82mTvY", year: 2001 , spotifyUrl: "7pbEKdMWHGwPQSDGfcNycM?si=b67db7513823474c" },
    { title: "The Less I Know The Better", artist: "Tame Impala", videoId: "2SUwOgmvzK4", year: 2015 , spotifyUrl: "6K4t31amVTZDgR3sKmwUJJ?si=d1a4e38de6434b19" },
    { title: "Dreams", artist: "Fleetwood Mac", videoId: "swJOIjjW69U", year: 1977 , spotifyUrl: "0ofHAoxe9vBkTCp2UQIavz?si=eb07ada06a0e4b26" },
    { title: "In the air tonight", artist: "Phil Collins", videoId: "8_Xs8nzrrkM", year: 1981 , spotifyUrl: "18AXbzPzBS8Y3AkgSxzJPb?si=0ad500297cf7403f" },
    { title: "Nightcall", artist: "Kavinsky", videoId: "MV_3Dpw-BRY", year: 2010 , spotifyUrl: "0U0ldCRmgCqhVvD6ksG63j?si=9d33398f4a954fdb" },
    { title: "Where Is My Mind?", artist: "Pixies", videoId: "49FB9hhoO6c", year: 1988 , spotifyUrl: "0KzAbK6nItSqNh8q70tb0K?si=936f25f82a5f4d84" },
    { title: "Hide And Seek", artist: "Imogen Heap", videoId: "UYIAfiVGluk", year: 2005 , spotifyUrl: "7mMlbJlXXo2mRtQ4R9sIzD?si=04358f889c7549d9" },
    { title: "Bitch, Don't Kill my vibe", artist: "Kendrick Lamar", videoId: "18HuC-jvi0U", year: 2012 , spotifyUrl: "5h5tBFnbcVioFXiOixTn6E?si=fc533aac777e4d5a" },
    { title: "Day 'N' Nite", artist: "Kid Cudi", videoId: "VrDfSZ_6f4U", year: 2008 , spotifyUrl: "60PAzFNW3vAiAiVK6DRJfB?si=d00323788ccc4c1e" },
    { title: "Price Tag", artist: "Jessie J", videoId: "KTRZOOWMJfc", year: 2011, spotifyUrl: "2fTsFCKRFQ5M0igJgabnLA?si=138a07ad6ab54a36" },
    { title: "Sweet Disposition", artist: "The Temper Trap", videoId:"jxKjOOR9sP", year: 2009, spotifyUrl: "2PLi7OmleXPNBrGLon3sUy?si=e3a4e28bb601493d" },
    { title: "Unwritten", artist: "Natasha Bedingfield", videoId:"cFFBSSntZgs", year: 2004, spotifyUrl: "3U5JVgI2x4rDyHGObzJfNf?si=2c92d8ce484440ca" },
    { title: "I'm Like a Bird", artist: "Nelly Furtado", videoId:"z_6_XTv4F_g", year: 2000, spotifyUrl: "2aGw77pZqli064JiPuXYRi?si=410661248650457a" },
    { title: "Where Is The Love?", artist: "Black Eyed Peas", videoId:"XSiP0epO0oU", year: 2003, spotifyUrl: "0xmjwnQ3FNE6HuWCt2nHdZ?si=4cc05479045443c8" },
    { title: "Creep", artist: "Radiohead", videoId:"XFkzRNyygfk", year: 1993, spotifyUrl: "70LcF31zb1H0PyJoS1Sx1r?si=9a52665f67014b98" },
    { title: "When I Was Your Man", artist: "Bruno Mars", videoId:"kON9fn01rUQ", year: 2012, spotifyUrl: "0nJW01T7XtvILxQgC5J7Wh?si=3390fac1a9e24268" },
    { title: "I'm Not The Only One", artist: "Sam Smith", videoId:"nCkpzqqog4k", year: 2014, spotifyUrl: "7795WJLVKJoAyVoOtCWqXN?si=1f5f43ae5d874a1f" },
    { title: "Everybody's Changing", artist: "Keane", videoId:"RSNmgE6L8AU", year: 2003, spotifyUrl: "0yac0FPhLRH9i9lOng3f81?si=36e9fce250774c09" },
    { title: "Bittersweet Symphony", artist: "The Verve", videoId:"JnRw8bXVbPI", year: 1997, spotifyUrl: "57iDDD9N9tTWe75x6qhStw?si=7dc83dc697c34e33" },
    { title: "Stay With Me", artist: "Sam Smith", videoId:"nLQFBq9ykjI", year: 2015, spotifyUrl: "5Nm9ERjJZ5oyfXZTECKmRt?si=0b54e277bd244246" },
    { title: "Somewhere Only We Know", artist: "Keane", videoId:"lAgplUg3P10", year: 2004, spotifyUrl: "1SKPmfSYaPsETbRHaiA18G?si=1fd5cc8a3a9841fe" },
    { title: "Stay", artist: "Rihanna", videoId:"5FvSaWHLGVs", year: 2012, spotifyUrl: "0GNI8K3VATWBABQFAzBAYe?si=6a066cb592654148" },
    { title: "Shallow", artist: "Lady Gaga", videoId:"BpIf43m2ZQI", year: 2018, spotifyUrl: "2VxeLyX666F8uXCJ0dZF8B?si=ce82a8b2c6e04523" },
    { title: "American Boy", artist: "Estelle + Kanye West", videoId: "Ic5vxw3eijY", year: 2008 , spotifyUrl: "6etwirSInfuJrSOlogS6Sh?si=e34caf664f9c4cc3" },
    { title: "Just the Two of Us", artist: "Grover Washington jr. + Bill Withers", videoId: "KYwA5k00p2I", year: 1972 , spotifyUrl: "1ko2lVN0vKGUl9zrU0qSlT?si=33996eeffb224103" },
    { title: "Killing Me Softly With His Song", artist: "Fugees", videoId: "H-RBJNqdnoM", year: 1996 , spotifyUrl: "0Q0IVlqMV64kNLlwjPj0Hl?si=9a784ed7118c408b" },
    { title: "If I Ain't Got You", artist: "Alicia Keys", videoId: "mAGQUsV6Nw", year: 2003 , spotifyUrl: "3XVBdLihbNbxUwZosxcGuJ?si=71e6fbbe179840be" },
    { title: "Back to Black", artist: "Amy Winehouse", videoId: "TJAfLE39ZZ8", year: 2006 , spotifyUrl: "3FAclTFfvUuQYnEsptbK8w?si=c30f41f7465d4a6a" },
    { title: "You Know I'm No Good", artist: "Amy Winehouse", videoId: "Xs_d5kwYqX8", year: 2006 , spotifyUrl: "4zQuwW24dweWcaX0fEJkP2?si=ec16fe65df5f4160" },
    { title: "Walk on the Wild Side", artist: "Lou Reed", videoId: "oG6fayQBm9w", year: 1972 , spotifyUrl: "5p3JunprHCxClJjOmcLV8G?si=83de2e0db3b7427f" },
    { title: "Rehab", artist: "Amy Winehouse", videoId: "KUmZp8pR1uc", year: 2006 , spotifyUrl: "3N4DI1vuTSX1tz7fa2NQZw?si=0142009728064bd6" },
    { title: "Have A Cigar", artist: "Pink Floyd", videoId: "tbdpv7G_PPg", year: 1975 , spotifyUrl: "5nfMtk9Zty1AITVo4YDhbb?si=f567d542b3e74d32" },
    { title: "The Passenger", artist: "Iggy Pop", videoId: "1r08Ca8fk5Y", year: 1977 , spotifyUrl: "15BQ7vEDv2LJuh8TxWIhtd?si=834a0b9886fc42df" },
    { title: "I Just Called To Say I Love You", artist: "Stevie Wonder", videoId: "58RgLQ_0Ars", year: 1984 , spotifyUrl: "6RNDeRnWsRMjPdNVgupZCs?si=2b083493c2bb4847" },
    { title: "America", artist: "Simon & Garfunkel", videoId: "Eo2ZsAOlvEM", year: 1968 , spotifyUrl: "6dfhF1BDGmhM69fnCb6wSC?si=e49a0f1289864b02" },
    { title: "Bridge Over Troubled Water", artist: "Simon & Garfunkel", videoId: "nvF5imxSaLI", year: 1970 , spotifyUrl: "6l8EbYRtQMgKOyc1gcDHF9?si=457fcf7ff2654c2a" },
    { title: "She", artist: "Elvis Costello", videoId: "6pjON5Y3O_Q", year: 1999, spotifyUrl: "31Tp5iKrVUayn65qhg06nE?si=26e77dee0dd246ea" },
    { title: "A Case of You", artist: "Joni Mitchell", videoId: "qAZp5JfDmz4", year: 1971, spotifyUrl: "3fNmCM9qLR9et5ejJe3PFi?si=27f4761fffb2460b" },
    { title: "Don't Speak", artist: "No Doubt", videoId:"ecgXVeJaYwE", year: 1995, spotifyUrl: "6urCAbunOQI4bLhmGpX7iS?si=2f00bcbeb53648da" },
    { title: "Kids", artist: "MGMT", videoId:"rdKfuwy6LUM", year: 2007, spotifyUrl: "1jJci4qxiYcOHhQR247rEU?si=02d4bd7032ab40e8" }, 
    { title: "Fell in Love With a Girl", artist: "The White Stripes", videoId:"STcAUluhVRQ", year: 2001, spotifyUrl: "21Qsj3cMVCx2xF2EVVNbEu?si=93b04f2037e546cc" }, 
    { title: "Will Do", artist: "TV on the Radio", videoId:"dXLpXu9T7j0", year: 2011, spotifyUrl: "5iHK8VI9XkJJdXQHCBXeM4?si=d3417e007ed2462d" }, 
    { title: "Bye Bye Bye", artist: "NSYNC", videoId:"C27NShgTQE4", year: 2000, spotifyUrl: "4r8lRYnoOGdEi6YyI5OC1o?si=84af3c6e821642b3" },
    { title: "Daft Punk Is Playing At My House", artist: "LCD Soundsystem", videoId:"Cj8JrQ9w5jY", year:2005 , spotifyUrl: "73mlvsfJM2qwlDUJxeaatI?si=98164206f2f94eda" },
    { title: "New Slang", artist: "The Shins", videoId:"zYwCmcB0XMw", year: 2001, spotifyUrl: "1wAqhAF1dynDjMy7m5CSSE?si=fc3163ac157a49f4" }, 
    { title: "Something", artist: "The Beatles", videoId:"UelDrZ1aFeY", year: 1969, spotifyUrl: "0pNeVovbiZHkulpGeOx1Gj?si=c53c7c0b32eb4f47" },
    { title: "Perfect Day", artist: "Lou Reed", videoId: "9wxI4KK9ZYo", year: 1972, spotifyUrl: "4TOMI010Sd4ZAX4aZ5TS85?si=3727b4a6858c4211" },
    { title: "No Surprises", artist: "Radiohead", videoId: "u5CVsCnxyXg", year: 1997, spotifyUrl: "10nyNJ6zNy2YVYLrcwLccB?si=d34d767b7c2641ad" },
    { title: "Runaway", artist: "Kanye West", videoId: "EMnQwBTJnMM", year: 2010, spotifyUrl: "3DK6m7It6Pw857FcQftMds?si=c08aef977bbc499b" }, 
]; 

export default songs;


